import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Section } from "components/layout";
import { H1 } from "components/typography";
import SEO from "components/Seo";
import Layout from "components/PageLayout";

const StyledSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading404 = styled.p`
  font-size: 16rem;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: white;
  font-size: 2.4rem;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <StyledSection>
      <Heading404>404</Heading404>
      <H1>Page not found</H1>
      <StyledLink to="/">Homepage &rarr;</StyledLink>
    </StyledSection>
  </Layout>
);

export default NotFoundPage;
